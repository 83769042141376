import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/otohireNewLogo.jpg";
import { IconButton } from "@mui/material";
import profileIcon from "../../../assets/profileIcon.png";
import { useDispatch } from "react-redux";
import { logout } from "../../../slices/authSlice";
import { persistor } from "../../../store";
import { useSelector } from "react-redux";

import profileFeedback from "../../../assets/icons/profileFeedback.png";
import profileHelp from "../../../assets/icons/profileHelp.png";
import profileReset from "../../../assets/icons/profileReset.png";
import profileLogout from "../../../assets/icons/profileLogout.png";
import { setCurrentItem } from "../../../slices/configSlice";
import { toast } from "react-toastify";
import { getResourceById } from "../../../functions/api/jobSeekers/getResource";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const user = useSelector((state) => state.auth.userData?.user);
  const [userProfile, setUserProfile] = useState("");

  useEffect(() => {
    const getResourceData = async () => {
      try {
        const res = await getResourceById(user?.id, accessToken);
        setUserProfile(res?.profilePhoto);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getResourceData();
  }, []);

  const handleLogout = () => {
    persistor.purge();
    dispatch(logout());
    navigate("/");
  };

  return (
    <StyledDiv>
      <div id="left">
        <img src={logo} onClick={() => navigate("/")} />
      </div>

      <div id="right">
        <Link to="/dashboard/jobseeker" className="link">
          <span className="coloredText">Back to Dashboard</span>
        </Link>
        <div className="profileIcon">
          {userProfile ? (
            <img src={userProfile} className="profileImgNew" />
          ) : (
            <img src={profileIcon} className="profileImg" />
          )}
        </div>

        <div class="dropdown" id="dropdown">
          <span
            className="titleText span"
            style={{ marginBottom: "0rem", border: "none" }}
            onClick={() => dispatch(setCurrentItem("profile"))}
          >
            Signed In as <b>{user?.firstName}</b>
          </span>
          <span onClick={() => navigate("/feedback")} className="span">
            <img src={profileFeedback} /> Feedback
          </span>
          <span onClick={() => navigate("/support")} className="span">
            <img src={profileHelp} /> Help
          </span>
          <span onClick={() => navigate("/reset/seeker")} className="span">
            <img src={profileReset} /> Reset Password
          </span>
          <span onClick={handleLogout} className="span">
            <img src={profileLogout} /> Logout
          </span>
        </div>
      </div>
    </StyledDiv>
  );
};

export default Header;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color);
  width: 90%;
  padding: 0% 5%;
  height: 4rem;
  z-index: 2000;
  background-color: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  position: fixed;

  #left > img {
    height: 3.5rem;
    margin-left: -5%;
    cursor: pointer;
  }

  h1 {
    margin: 0;
    color: #add8e6;
  }

  .coloredText {
    font-size: 0.85rem;
    color: var(--color);
    font-weight: 600;
    cursor: pointer;
  }

  .coloredText:hover {
    font-weight: 700;
    text-decoration: underline;
  }

  #right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .profileIcon {
    position: relative;
  }

  .profileImg {
    width: 1.5rem;
    cursor: pointer;
  }

  .profileImgNew {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
  }

  .dropdown {
    display: none;
    position: absolute;
    top: 75%;
    right: 0.5vw;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 1rem;
    font-weight: 400;
    font-size: 0.8rem;
    border-radius: 0.5rem;

    .titleText {
      font-weight: 400;
      font-size: 0.9rem;
    }

    img {
      width: 1rem;
    }
  }

  .dropdown span {
    display: flex;
    padding: 8px 10px;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s ease;
    border-bottom: 0.1rem groove #f6f6f6;
    gap: 0.5rem;
    align-items: center;
  }

  .dropdown span:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
  }

  .profileIcon:hover + #dropdown {
    display: block;
  }

  .dropdown:hover {
    display: block;
  }

  .demo {
    background: linear-gradient(to bottom, #2282a4, var(--cyan));
    padding: 0.6rem 0.6rem;
    color: var(--white);
    font-weight: 600;
    font-size: 0.85rem;
    cursor: pointer;
    border-radius: 0.5rem;
    border: none;
  }

  .demo:hover {
    background: linear-gradient(to bottom, #8accdc, var(--cyan));
  }

  .link {
    text-decoration: none;
  }

  .Icon {
    color: var(--white);
  }
`;
