import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import { createInterview } from "../functions/api/interview/createInterview";
import { updateStatus } from "../functions/api/interview/updateStatus";
import { getQuestion } from "../functions/api/interview/getQuestion";
import { submitAnswer } from "../functions/api/interview/submitAnswer";
import { getScore } from "../functions/api/interview/getScore";
import Loader from "../components/commonComponents/Loader";
import Timer from "../components/Interviews/CurrentInterview/Timer";
import logo from "../assets/otohireNewLogo.jpg";
import { submitAnswerWithFile } from "../functions/api/interview/submitAnswerWithFile";
import { ReactMediaRecorder } from "react-media-recorder";
import startRecBtn from "../assets/icons/startRecBtn.png";
import stopRecBtn from "../assets/icons/stopRecBtn.png";
import idle from "../assets/icons/recStatusIdle.png";
import recording from "../assets/icons/recStatusRecording.png";
import stopped from "../assets/icons/recStatusStopped.png";
import CodeEditor from "./CodeEditor";
import { codingQuestionFormat } from "../utils/codingQuestionFormat";
import CodingQueInterface from "../components/Interviews/SeekerDashboard/CodingQueInterface";
import CommonButton from "../components/Interviews/SeekerDashboard/seekerCommonComponents/CommonButton";
import InterviewTerms from "../components/Interviews/SeekerDashboard/seekerCommonComponents/InterviewTerms";
import McqQueInterface from "../components/Interviews/SeekerDashboard/McqQueInterface";
import { toast } from "react-toastify";
import { getEmployer } from "../functions/api/employers/profile/getEmployer";
import { useDispatch } from "react-redux";
import { updateInterview } from "../slices/interviewSlice";
import { getInterviewDetails } from "../functions/api/interview/getInterviewDetails";
import pauseRecBtn from "../assets/icons/pauseRecBtn.png";
import resumeRecBtn from "../assets/icons/resumeRecBtn.png";
import useWebcamCapture from "../components/Interviews/ProctoringHooks/useWebcamCapture";
import useScreenCapture from "../components/Interviews/ProctoringHooks/useScreenCapture";
import ConfigurableModal from "../components/Interviews/SeekerDashboard/seekerCommonComponents/ConfigurableModal";
import { removeAllFailedUploads } from "../slices/uploadSnapSlice";
import ErrorComponent from "./HandleError";

const OngoingInterview = ({
  start,
  handleStart,
  interviewState,
  escapeFullScreenCount,
  tabFocusCount,
}) => {
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const [interviewData, setInterviewData] = useState();
  const { interviewId } = useParams();
  console.log(interviewState);
  const [data, setData] = useState(null);
  const [id, setId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [input, setInput] = useState("");
  const [audioData, setAudioData] = useState(null);
  const [isFailed, setFailed] = useState(true);

  const [agreed, setAgreed] = useState(false);
  const [language, setLanguage] = useState("markdown");
  const [theme, setTheme] = useState(false);

  const [isMock, setMock] = useState(false);
  const navigate = useNavigate();

  // TIMER CODE
  const [initialMinutes, setInitialMinutes] = useState(60); // Default to 60 minutes
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const [ongoingRecord, setOngoingRecord] = useState(false);
  const [expiryTime, setExpiryTime] = useState(null);

  const dispatch = useDispatch();
  // hooks for screenCapture and webcamCapture
  const {
    showModal,
    setShowModal,
    handleScreenShotAccess,
    stopCapturingScreenshots,
    permissionGranted,
  } = useScreenCapture();

  const {
    webcamAccessGranted,
    handleWebcamAccess,
    stopCapturingWebcamScreenshots,
  } = useWebcamCapture();

  useEffect(() => {
    dispatch(removeAllFailedUploads());
  }, []);

  useEffect(() => {
    try {
      const getData1 = async () => {
        const res = await getInterviewDetails(interviewId, accessToken);
        if (res) {
          const resArr = Object.keys(res?.data);
          const flag = resArr.findIndex((val) => val === "fetchQuestionError");
          const flag2 =
            resArr.includes("jdId") && resArr.includes("initiatedById");
          setMock(!flag2);
          setFailed(flag === -1 ? false : true);

          if (res?.data?.status == "COMPLETED") {
            toast.warn("Interview is already completed");
            navigate("/dashboard/jobseeker");
          }
          setInterviewData(res?.data);
          // Set the initial minutes based on the API response
          if (res?.data?.expiryInMin) {
            const now = new Date();
            const expiryDate = new Date(
              now.getTime() + res.data.expiryInMin * 60000
            );
            setExpiryTime(expiryDate);
          }
        }
      };
      getData1();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }, []);

  useEffect(() => {
    const getEmployerData = async (employerClientCode) => {
      try {
        const res = await getEmployer(accessToken, employerClientCode);
        if (res) {
          setCompanyDetails(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    if (interviewData && interviewData?.clientCode !== "otohire") {
      getEmployerData(interviewData?.clientCode);
    }
  }, [interviewData]);

  useEffect(() => {
    let timer;

    if (isRunning) {
      timer = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          setIsRunning(false);
          clearInterval(timer);
        } else {
          if (seconds === 0) {
            setMinutes(minutes - 1);
            setSeconds(59);
          } else {
            setSeconds(seconds - 1);
          }
        }
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isRunning, minutes, seconds]);

  const startTimer = () => {
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setIsRunning(false);
    setMinutes(initialMinutes);
    setSeconds(0);
  };

  const ExpiryTimer = ({ expiryTime }) => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000);

      return () => clearInterval(timer);
    }, []);

    if (!expiryTime) return null;

    const timeLeft = expiryTime - currentTime;
    if (timeLeft <= 0) return <div>Time Expired</div>;

    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return (
      <div className="timer">
        {hours.toString().padStart(2, "0")}:
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </div>
    );
  };
  //TIMER CODE ENDS
  useEffect(() => {
    if (!accessToken) navigate("/login");
  }, []);

  // useEffect(() => {
  //   const handleTermination = async () => {
  //     if (tabFocusCount > 5 || escapeFullScreenCount > 5) {
  //       await handleSubmitInterview();
  //     }
  //   }
  //   handleTermination();
  // }, [tabFocusCount, escapeFullScreenCount])

  const handleStop = (blobUrl, blob) => {
    setAudioData(blob);
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmitAnswer = async (id, lastQuestion) => {
    if (ongoingRecord) {
      toast.warning("Please stop recording before moving forward", 5000);
      return;
    }

    try {
      setLoaderMessage("Submitting Answer... please wait");
      setIsLoading(true);
      setId(id + 1);
      if (audioData) {
        const formData = new FormData();
        formData.append("file", audioData, "recorded_audio.wav");
        formData.append("dto", JSON.stringify({ id, lastQuestion }));
        const res = await submitAnswerWithFile(
          formData,
          id,
          lastQuestion,
          interviewId,
          accessToken
        );
        if (res) setAudioData(null);
      } else {
        const res = await submitAnswer(
          input,
          id,
          lastQuestion,
          interviewId,
          accessToken
        );
        setInput("");
      }
      setIsLoading(false);
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const handleSubmitInterview = async () => {
    if (ongoingRecord) {
      toast.warning("Please stop recording before moving forward", 5000);
      return;
    }

    handleStopAccess();

    try {
      setLoaderMessage("Evaluating the Score... please wait");
      setIsLoading(true);
      const submitRes = await updateStatus(
        interviewId,
        "completed",
        accessToken
      );
      if (submitRes) {
        navigate(`/score/${interviewId}`);
      }
      setIsLoading(false);
      localStorage.setItem("time", JSON.stringify({ minutes, seconds }));
      stopTimer();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  // const startInterview = async () => {
  //   if (permissionGranted) {
  //     await getData(true, interviewState);
  //   } else {
  //     toast.warning("Please allow permissions before starting interview", 8000);
  //   }
  // }

  const startInterview = async () => {
    if (isMock || (webcamAccessGranted && permissionGranted)) {
      await getData(true, interviewState);
    } else if (!webcamAccessGranted) {
      toast.warning(
        "Please allow webcam access before starting interview",
        8000
      );
    } else {
      toast.warning("Please allow permissions before starting interview", 8000);
    }
  };

  const handleAccessButtonClick = async () => {
    if (!webcamAccessGranted) {
      handleWebcamAccess();
    }
    handleScreenShotAccess();
  };

  const handleStopAccess = async () => {
    stopCapturingScreenshots();
    stopCapturingWebcamScreenshots();
  };

  const handleGoBack = () => {
    navigate("/dashboard/jobseeker");
    toast.success("Successfully navigated to dashboard");
  };

  async function getData(flag, state = "start") {
    try {
      setLoaderMessage("Getting new Question... please wait");
      setIsLoading(true);
      const fetchedData = await getQuestion(
        interviewId,
        accessToken,
        state === "resume"
      );
      setData(fetchedData?.data[0]);
      setIsLoading(false);
      setInput("");
      handleStart();
      startTimer();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }

  return (
    <>
      {showModal && (
        <ConfigurableModal
          open={showModal}
          setOpen={setShowModal}
          component={
            <div>
              <h3>Allow Screen Sharing Access?</h3>
              <button
                onClick={() => {
                  handleAccessButtonClick();
                }}
              >
                Allow Access
              </button>
              <button onClick={handleGoBack}>Go Back to Dashboard</button>
            </div>
          }
          style={{ width: "90%", height: "85%" }}
        />
      )}
      {isLoading ? (
        <Loader message={loaderMessage} />
      ) : interviewData?.fetchQuestionError ? (
        <ErrorComponent />
      ) : (
        !isFailed && (
          <StyledInterview>
            <div className="head">
              <img src={logo} className="logo" />
              <span className="title">
                {interviewData?.clientCode === "otohire" ? (
                  <div className="titleBox">
                    <span className="text">
                      Test Type: {interviewData?.testType?.toUpperCase()}
                    </span>
                    <span className="text">
                      Difficulty Level:{" "}
                      {interviewData?.difficultyLevel?.toUpperCase()}
                    </span>
                  </div>
                ) : (
                  <div className="titleBox">
                    <img
                      className="companyLogo"
                      src={companyDetails?.companyLogo}
                    />
                    <span className="companyName">
                      {companyDetails?.companyName}
                    </span>
                  </div>
                )}
              </span>
              <div className="middleBox">
                <span className="warningText">
                  Tab unFocus Count: {tabFocusCount}/5
                </span>
                <span className="warningText">
                  FullScreen Exit Count: {escapeFullScreenCount}/5
                </span>
              </div>

              <div className="topLastBox">
                {!data?.lastQuestion &&
                  start &&
                  data?.questionType == "coding" && (
                    <CommonButton
                      text="Next Question"
                      func={async () => {
                        await handleSubmitAnswer(data?.id, data?.lastQuestion);
                        {
                          !ongoingRecord && (await getData(false));
                        }
                      }}
                      className="btn"
                    />
                  )}
                <Timer minutes={minutes} seconds={seconds} />
                {start &&
                  data?.questionType == "coding" &&
                  (data?.lastQuestion ? (
                    <CommonButton
                      text="Submit"
                      func={async () => {
                        await handleSubmitAnswer(data?.id, data?.lastQuestion);
                        await handleSubmitInterview();
                      }}
                    />
                  ) : (
                    <CommonButton
                      text="Finish Interview"
                      func={async () => {
                        await handleSubmitAnswer(data?.id, data?.lastQuestion);
                        await handleSubmitInterview();
                      }}
                    />
                  ))}
              </div>
            </div>

            {start ? (
              <>
                {data?.questionType == "coding" ? (
                  <CodingQueInterface
                    queComp={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: codingQuestionFormat(data?.question),
                        }}
                        className="questionText"
                      ></div>
                    }
                    codeEditorComp={
                      <CodeEditor
                        input={input}
                        setInput={setInput}
                        language={language}
                        setLanguage={setLanguage}
                        theme={theme}
                        setTheme={setTheme}
                      />
                    }
                    theme={theme}
                    seqOrder={data?.seqOrder}
                  />
                ) : data?.questionType === "mcq" ? (
                  <McqQueInterface
                    queComp={
                      <div className="questionText">{data?.question}</div>
                    }
                    options={data?.mcqOptions}
                    value={input}
                    setValue={setInput}
                    seqOrder={data?.seqOrder}
                  />
                ) : (
                  <div className="subjectiveBox">
                    <div className="textBox">
                      <span className="seqOrder">{data?.seqOrder}. </span>
                      <div
                        dangerouslySetInnerHTML={{ __html: data?.question }}
                        className="questionText questionText2"
                      ></div>
                    </div>
                    <textarea
                      rows={10}
                      value={input}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                )}

                {data?.lastQuestion && data?.questionType !== "coding" ? (
                  <div className="generalSubmitBox">
                    {data?.questionType == "general" && (
                      <div className="btnBox2">
                        <ReactMediaRecorder
                          audio
                          onStop={handleStop}
                          render={({
                            status,
                            startRecording,
                            stopRecording,
                            pauseRecording,
                            resumeRecording,
                            mediaBlobUrl,
                          }) => {
                            return (
                              <AudioBox>
                                <div className="btnImgBox">
                                  {status === "recording" || status === "paused"
                                    ? setOngoingRecord(true)
                                    : setOngoingRecord(false)}
                                  {(status === "idle" ||
                                    status === "stopped") && (
                                    <div className="btn1">
                                      <img
                                        onClick={startRecording}
                                        className="btnImg"
                                        src={startRecBtn}
                                      />
                                      <span className="btn1Text">
                                        Start recording your answer
                                      </span>
                                    </div>
                                  )}

                                  {(status === "recording" ||
                                    status === "paused") && (
                                    <div className="btn2Box">
                                      {status === "recording" && (
                                        <div className="btn2">
                                          <img
                                            onClick={pauseRecording}
                                            className="btnImg"
                                            src={pauseRecBtn}
                                          />
                                          <span className="btn2Text">
                                            Pause recording
                                          </span>
                                        </div>
                                      )}
                                      {status === "paused" && (
                                        <div className="btn2">
                                          <img
                                            onClick={resumeRecording}
                                            className="btnImg"
                                            src={resumeRecBtn}
                                          />
                                          <span className="btn2Text">
                                            Resume recording
                                          </span>
                                        </div>
                                      )}
                                      <div className="btn2">
                                        <img
                                          onClick={stopRecording}
                                          className="btnImg"
                                          src={stopRecBtn}
                                        />
                                        <span className="btn2Text">
                                          Stop recording
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <audio src={mediaBlobUrl} controls />
                                <span id="status1">
                                  {status === "idle" && (
                                    <img
                                      id="idle"
                                      src={idle}
                                      className="statusIcon"
                                    />
                                  )}
                                  {status === "recording" && (
                                    <img
                                      id="rec"
                                      src={recording}
                                      className="statusIcon"
                                    />
                                  )}
                                  {status === "stopped" && (
                                    <img
                                      id="stop"
                                      src={stopped}
                                      className="statusIcon"
                                    />
                                  )}
                                  <span id="status2">{status}</span>
                                </span>
                              </AudioBox>
                            );
                          }}
                        />
                      </div>
                    )}

                    <CommonButton
                      text="Submit Interview"
                      func={async () => {
                        await handleSubmitAnswer(data?.id, data?.lastQuestion);
                        await handleSubmitInterview();
                      }}
                    />
                  </div>
                ) : data?.questionType !== "coding" ? (
                  <>
                    <div className="btnBox1">
                      <CommonButton
                        text="Finish Interview"
                        func={async () => {
                          await handleSubmitAnswer(
                            data?.id,
                            data?.lastQuestion
                          );
                          await handleSubmitInterview();
                        }}
                        width="12%"
                      />

                      {data?.questionType == "general" && (
                        <div className="btnBox2">
                          <ReactMediaRecorder
                            audio
                            onStop={handleStop}
                            render={({
                              status,
                              startRecording,
                              stopRecording,
                              pauseRecording,
                              resumeRecording,
                              mediaBlobUrl,
                            }) => {
                              return (
                                <AudioBox>
                                  <div className="btnImgBox">
                                    {status === "recording" ||
                                    status === "paused"
                                      ? setOngoingRecord(true)
                                      : setOngoingRecord(false)}
                                    {(status === "idle" ||
                                      status === "stopped") && (
                                      <div className="btn1">
                                        <img
                                          onClick={startRecording}
                                          className="btnImg"
                                          src={startRecBtn}
                                        />
                                        <span className="btn1Text">
                                          Start recording your answer
                                        </span>
                                      </div>
                                    )}

                                    {(status === "recording" ||
                                      status === "paused") && (
                                      <div className="btn2Box">
                                        {status === "recording" && (
                                          <div className="btn2">
                                            <img
                                              onClick={pauseRecording}
                                              className="btnImg"
                                              src={pauseRecBtn}
                                            />
                                            <span className="btn2Text">
                                              Pause recording
                                            </span>
                                          </div>
                                        )}
                                        {status === "paused" && (
                                          <div className="btn2">
                                            <img
                                              onClick={resumeRecording}
                                              className="btnImg"
                                              src={resumeRecBtn}
                                            />
                                            <span className="btn2Text">
                                              Resume recording
                                            </span>
                                          </div>
                                        )}
                                        <div className="btn2">
                                          <img
                                            onClick={stopRecording}
                                            className="btnImg"
                                            src={stopRecBtn}
                                          />
                                          <span className="btn2Text">
                                            Stop recording
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <audio src={mediaBlobUrl} controls />
                                  <span id="status1">
                                    {status === "idle" && (
                                      <img
                                        id="idle"
                                        src={idle}
                                        className="statusIcon"
                                      />
                                    )}
                                    {status === "recording" && (
                                      <img
                                        id="rec"
                                        src={recording}
                                        className="statusIcon"
                                      />
                                    )}
                                    {status === "stopped" && (
                                      <img
                                        id="stop"
                                        src={stopped}
                                        className="statusIcon"
                                      />
                                    )}
                                    <span id="status2">{status}</span>
                                  </span>
                                </AudioBox>
                              );
                            }}
                          />
                        </div>
                      )}

                      <CommonButton
                        text="Next Question"
                        func={async () => {
                          await handleSubmitAnswer(
                            data?.id,
                            data?.lastQuestion
                          );
                          {
                            !ongoingRecord && (await getData(false));
                          }
                        }}
                        width="12%"
                        className="btn"
                      />
                    </div>
                  </>
                ) : (
                  <span></span>
                )}
              </>
            ) : (
              <div className="startInterviewBox">
                <InterviewTerms />
                {!isMock && (
                  <label>
                    <input
                      type="checkbox"
                      onClick={() => setAgreed(!agreed)}
                      className="checkbox"
                    />
                    I agree
                  </label>
                )}
                <div className="btnBoxLast">
                  {!isMock && (
                    <CommonButton
                      text={"Allow Permissions"}
                      func={() => handleAccessButtonClick()}
                    />
                  )}
                  <CommonButton
                    text={`${interviewState
                      ?.charAt(0)
                      ?.toUpperCase()}${interviewState
                      ?.slice(1)
                      ?.toLowerCase()} Interview`}
                    func={() => startInterview()}
                    // disabled={!agreed || !permissionGranted}
                    disabled={
                      !isMock &&
                      (!agreed || !webcamAccessGranted || !permissionGranted)
                    }
                  />
                </div>
              </div>
            )}
          </StyledInterview>
        )
      )}
    </>
  );
};

export default OngoingInterview;

const StyledInterview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: 0rem auto;
  gap: 0.5rem;

  label {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .checkbox {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }

  .startInterviewBox {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .btnBoxLast {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
    }
  }

  .questionText {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .subjectiveBox {
    margin-top: 3rem;
    width: 100%;

    .textBox {
      display: flex;
      gap: 1rem;
    }

    .seqOrder {
      font-weight: 600;
    }

    .questionText2 {
      margin-bottom: 1.5rem;
    }
  }

  .codingMainBox {
    display: flex;
    width: 100%;
  }

  .statusIcon {
    width: 1.5rem;
  }

  .timer {
    width: 3rem;
    background-color: var(--white);
    color: var(--color);
    border: 0.1rem solid var(--cyan);
    border-radius: 0.5rem;
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
  }

  .head {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0 0.25rem 0;
    width: 100%;
    align-items: center;
    height: 2.5rem;

    .middleBox {
      display: flex;
      gap: 1rem;
      font-size: 0.9rem;
      font-weight: 600;
      font-family: var(--font);

      .warningText {
      }
    }

    .topLastBox {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .logo {
      height: 100%;
    }

    .title {
      font-size: 0.9rem;
      font-weight: 600;
      font-family: var(--font);

      .titleBox {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 0.9rem;
        font-family: var(--font);

        .text {
          font-size: 0.9rem;
          font-weight: 600;
          font-family: var(--font);
        }

        b {
          font-weight: 500;
          font-family: var(--font);
        }

        .companyLogo {
          width: 2rem;
          height: 2rem;
        }

        .companyName {
          font-size: 0.9rem;
          font-weight: 500;
        }
      }
    }
  }

  .generalSubmitBox {
    display: flex;
    width: calc(100% - 2rem);
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    padding: 0 0rem 0 2rem;
  }

  .btnBox1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }

  .btnBox2 {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .btn2Box {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
  }

  .btn1,
  .btn2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .btn1Text,
  .btn2Text {
    font-size: 0.65rem;
    font-weight: 400;
    position: absolute;
    top: 2.5rem;
    display: none;
    width: 9rem;
  }

  .btn1:hover {
    .btn1Text {
      display: flex;
    }
  }

  .btn2:hover {
    .btn2Text {
      display: flex;
      left: -1.3rem;
    }
  }

  .btnImg {
    width: 2rem;
    cursor: pointer;
  }

  input {
    height: 7rem;
    width: 100%;
    border-radius: 0.5rem;
  }

  p,
  h3 {
    font-size: 1.5rem;
  }

  textarea {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
  }
`;

const AudioBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  .btnImgBox {
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  #status2 {
    font-size: 0.6rem;
    display: none;
    position: absolute;
    top: 2rem;
    width: 100%;
    justify-content: center;
  }

  #status1 {
    position: relative;
  }

  #status1:hover {
    #status2 {
      display: flex;
    }
  }
`;
