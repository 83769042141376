import React, { useState, useEffect } from "react";
import styled from "styled-components";
import save from "../../../../assets/icons/save2.png";
import saved from "../../../../assets/icons/saved.png";
import share from "../../../../assets/icons/share.png";
import view from "../../../../assets/icons/visible.png";
import CommonDrawer from "../../../commonComponents/CommonDrawer";
import JobApplicationModal from "../seekerCommonComponents/JobApplicationModal";
import ConfigurableModal from "../seekerCommonComponents/ConfigurableModal";
import { saveJob } from "../../../../functions/api/jobApplication/saveJob";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import JdsDetails from "../../EmployerDashboard/sidebarPages/JdsDetails";
import { getEmployer } from "../../../../functions/api/employers/profile/getEmployer";
import {
  Pagination,
  PaginationSizeFilter,
} from "../../../commonComponents/Pagination";
import DOMPurify from "dompurify";
import experienceIcon from "../../../../assets/icons/JdDetails/experienceIcon.png";
import ctcIcon from "../../../../assets/icons/JdDetails/ctcIcon.png";
import locationIcon from "../../../../assets/icons/JdDetails/locationIcon.png";
import descriptionIcon from "../../../../assets/icons/JdDetails/active-job-desc.png";
import { dateConvert } from "../../../../utils/globalFunctions";

function Row(props) {
  const { row, index, setTrigger, trigger, page, size } = props;
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );

  const [openBasic, setOpenBasic] = useState(false);

  const [state, setState] = React.useState({
    right: false,
  });

  const [companyDetails, setCompanyDetails] = useState({});
  const sanitizedDesc = DOMPurify?.sanitize(row?.description);
  const plainTextDescription = sanitizedDesc?.replace(/<[^>]+>/g, "");

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getEmployer(accessToken, row?.clientCode);
        if (res) {
          setCompanyDetails(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    getData();
  }, [page, size, row?.clientCode]);

  const handleSave = async () => {
    try {
      const payload = {
        clientCode: row?.clientCode,
        jdId: row?.jdId,
      };
      const res = await saveJob(payload, accessToken, clientCode);
      if (res) {
        toast.success("Successfully saved");
        setTrigger((prev) => !prev);
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  // Open and close drawer for job details view.
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleClose = () => {
    setOpenBasic(false);
  };

  return (
    <CardMainBox>
      <ConfigurableModal
        open={openBasic}
        setOpen={setOpenBasic}
        component={
          <JobApplicationModal
            jdId={row?.jdId}
            empClientCode={row?.clientCode}
            handleClose={handleClose}
            setTrigger={setTrigger}
          />
        }
        style={{ width: "50%", height: "70%" }}
      />
      <Card>
        <div className="box1">
          <div className="left">
            <img src={companyDetails?.companyLogo} className="logo" />
            <div className="textBox">
              <span className="title">{row?.title}</span>
              <span className="subTitle">{companyDetails?.companyName}</span>
            </div>
          </div>
          <div className="right">
            <div className="box">
              <CommonDrawer
                toggleDrawer={toggleDrawer}
                state={state}
                component={
                  <JdsDetails
                    Jds={row}
                    companyName={companyDetails?.companyName}
                  />
                }
              />
              <img
                src={view}
                onClick={toggleDrawer("right", true)}
                className="icon"
              />
              {row?.saved ? (
                <img src={saved} className="icon1" />
              ) : (
                <img src={save} onClick={handleSave} className="icon" />
              )}
              {/* <img src={share} className="icon" /> */}
            </div>
          </div>
        </div>

        <div className="box2">
          {row?.exp && (
            <span className="text">
              <img src={experienceIcon} /> {row?.exp} Yrs
            </span>
          )}{" "}
          {row?.exp && <>|</>}
          {row?.ctc && (
            <span className="text">
              <img src={ctcIcon} /> {row?.ctc} Lacs PA
            </span>
          )}{" "}
          {row?.exp && <>|</>}
          {row?.location && (
            <span className="text">
              <img src={locationIcon} /> {row?.location}
            </span>
          )}
        </div>

        <div className="box3">
          <span className="text">
            <img src={descriptionIcon} />{" "}
            {plainTextDescription?.length > 125
              ? plainTextDescription?.substring(0, 125) + "..."
              : plainTextDescription}
          </span>
        </div>

        <div className="box4">
          {row?.skills?.split(",")?.map((skill, index) => (
            <span className="text" key={index}>
              {skill}
            </span>
          ))}
        </div>

        <div className="box5">
          <span className="left">
            {row?.lastPublicAt && dateConvert(row?.lastPublicAt)}
          </span>
          <span className="right">
            {row?.applied ? (
              <button className="btn1">Applied</button>
            ) : (
              <button onClick={() => setOpenBasic(true)} className="btn">
                Apply
              </button>
            )}
          </span>
        </div>
      </Card>
    </CardMainBox>
  );
}

const AllJobsListNew = ({
  jobListings,
  page,
  size,
  setPage,
  handlePageChange,
  handleSizeChange,
  total,
  setTrigger,
  trigger,
}) => {
  return (
    <>
      {total === 0 ? (
        <NotFoundTitle>
          We Couldn't Find Any Job Matches for Your Search.
        </NotFoundTitle>
      ) : (
        <Container1>
          <StyledBox>
            {jobListings?.map((row, index) => (
              <Row
                key={row?.jobId}
                row={row}
                index={index}
                setTrigger={setTrigger}
                trigger={trigger}
                page={page}
                size={size}
              />
            ))}
          </StyledBox>
          <div className="paginationBox">
            <PaginationSizeFilter
              size={size}
              handleSizeChange={handleSizeChange}
            />
            <Pagination
              total={total}
              size={size}
              page={page}
              handlePageChange={handlePageChange}
              setPage={setPage}
            />
          </div>
        </Container1>
      )}
    </>
  );
};

export default AllJobsListNew;

const StyledBox = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const NotFoundTitle = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
  padding-top: 3rem;
`;

const Container1 = styled.div`
  width: 95%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0rem;

  .paginationBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0.5rem 0 1.5rem 0;
  }
`;

const CardMainBox = styled.div`
  width: 100%;
  border: 0.05rem solid lightgrey;
  border-radius: 1rem;
  background-color: var(--white);
  color: var(--color);
  font-family: var(--font);
`;

const Card = styled.div`
  padding: 1rem 1.75rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .box1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      gap: 1rem;
      padding-bottom: 0.75rem;
      .logo {
        width: 2.5rem;
        height: 2.5rem;
        border: 0.025rem solid lightgrey;
        border-radius: 0.25rem;
      }

      .textBox {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        .title {
          font-size: 1rem;
          font-weight: 700;
        }
        .subTitle {
          font-size: 0.9rem;
          font-weight: 600;
        }
      }
    }

    .right {
      .box {
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        .icon {
          width: 0.8rem;
          height: 0.8rem;
          cursor: pointer;
          border: 0.08rem solid grey;
          padding: 0.3rem;
          border-radius: 0.3rem;
        }

        .icon1 {
          width: 0.8rem;
          height: 0.8rem;
          border: 0.08rem solid grey;
          padding: 0.3rem;
          border-radius: 0.3rem;
        }
      }
    }
  }

  .box2 {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .text {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      font-size: 0.9rem;
      font-weight: 500;

      img {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .box3 {
    .text {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: start;
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 1.3rem;

      img {
        width: 1rem;
        height: 1rem;
        padding-top: 0.1rem;
      }
    }
  }

  .box4 {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding-top: 0.5rem;

    .text {
      font-size: 0.75rem;
      font-weight: 400;
      border: 0.025rem solid lightgrey;
      border-radius: 0.25rem;
      padding: 0.15rem 0.25rem;
    }
  }

  .box5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;

    .left {
      font-weight: 500;
    }

    .right {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .btn {
        background-color: var(--cyan);
        padding: 0.5rem 0.8rem;
        border: none;
        color: var(--white);
        font-size: 0.9rem;
        font-weight: 600;
        border-radius: 0.5rem;
        cursor: pointer;
        text-decoration: none;
        font-family: var(--font);
      }

      .btn1 {
        background-color: var(--white);
        padding: 0.5rem 0.8rem;
        color: var(--cyan);
        font-size: 0.9rem;
        font-weight: 600;
        border-radius: 0.5rem;
        text-decoration: none;
        font-family: var(--font);
        border: 0.08rem solid var(--cyan);
      }
    }
  }
`;
