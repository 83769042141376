import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { canFeedBacks } from "./canFeedBacks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["clean"],
  ],
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "align",
];



const convertHtmlToStructuredText = (htmlString) => {
  console.log(htmlString)
  const doc = new DOMParser().parseFromString(htmlString, "text/html");

  function processNode(node) {
      let text = "";

      node.childNodes.forEach((child) => {
          // Handle line breaks for specific tags
          if (child.nodeName === "P" || child.nodeName === "DIV") {
              text += "\n\n"; 
          } else if (child.nodeName === "BR") {
              text += "\n"; 
          }

         
          let prefix = "";
          let suffix = "";

          switch (child.nodeName) {
              case "STRONG":
                  prefix = "[strong]";
                  suffix = "[/strong]";
                  break;
              case "B":
                  prefix = "[b]";
                  suffix = "[/b]";
                  break;
              case "I":
                  prefix = "[i]";
                  suffix = "[/i]";
                  break;
              case "U":
                  prefix = "[u]";
                  suffix = "[/u]";
                  break;
              case "EM":
                  prefix = "[em]";
                  suffix = "[/em]";
                  break;
              default:
                  break;
          }

          if (child.nodeType === Node.TEXT_NODE) {
              text += child.textContent;
          } else {
              text += prefix + processNode(child) + suffix;
          }
      });

      return text;
  }

  return processNode(doc.body).trim();
};


const FeedbackForm = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [candidateFeedback, setCandidateFeedback] = useState({
    remarks: "",
    rating: 0.0,
  });
  const [feedbackData, setFeedbackData] = useState({});
  const [errorHandle, setErrorHandle] = useState({
    toggle: false,
    error: "",
  });
  const [selectedOption, setSelectedOption] = useState(true);
  const navigate = useNavigate();

  const handleRemarksChange = useCallback(
    (value) => {
      setCandidateFeedback((prevState) => ({
        ...prevState,
        remarks: value,
      }));
    },
    [setCandidateFeedback]
  );

  const handleRadioChange = (event) => {
    const canStatus = event.target.value === "qualified" ? true : false;
    console.log(canStatus)
    setSelectedOption(canStatus);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { rating, remarks } = candidateFeedback;

    if (!remarks) {
      return toast.warn("Remark should not be empty");
    }
    if (rating <= 0) {
      return toast.warn("Rating must be greater than 0");
    }
    if (selectedOption === "") {
      return toast.warn("Select candidate status");
    }
    console.warn(remarks);

    const config = {
      interviewId: feedbackData?.data?.interviewId,
      rating: parseFloat(rating),
      // remarks: extractPlainTextWithNewlines(remarks),
      remarks:convertHtmlToStructuredText(remarks),
      qualified: selectedOption,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/interviews/interviewer/feedbacks?token=${token}`,
        config
      );
      if (res?.data?.status === "SUCCESS") {
        navigate("/interviews/feedback/submission");
      }
    } catch (error) {
      console.error(error);
      toast.error("Submission failed. Please try again.");
    }
  };

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const res = await canFeedBacks(token);
        if (res.data?.status === "SUCCESS") {
          setFeedbackData(res.data);
        }
        setErrorHandle((prev) => ({ ...prev, toggle: true, error: "" }));
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, { autoClose: 8000 });
        setErrorHandle((prev) => ({ ...prev, toggle: false, error: errMsg }));
      }
    };

    fetchFeedback();
  }, [token]);

  if (!token) {
    return (
      <CenterContainer>
        <div className="containers">
          <h1 className="stdName">{errorHandle?.error}</h1>
          <p>Unique Token Not Found</p>
        </div>
      </CenterContainer>
    );
  }

  if (errorHandle.toggle && !feedbackData?.data?.canFeedback) {
    return (
      <CenterContainer>
        <div className="containers">
          <h1 className="stdName">{feedbackData?.data?.candidateName}</h1>
          <p>Your submission time out</p>
        </div>
      </CenterContainer>
    );
  }

  return errorHandle.toggle ? (
    <StyledDiv>
      <div className="content">
        <div className="form-container">
          <h2 className="title">
            Candidate Feedback Form_&nbsp;
            <strong>{feedbackData?.data?.candidateName || "Anonymous"}</strong>
          </h2>
          <form onSubmit={handleSubmit}>
            <StyledReactQuill
              value={candidateFeedback.remarks}
              onChange={handleRemarksChange}
              modules={modules}
              formats={formats}
              placeholder="Remarks"
            />
            <TextField
              id="outlined-basic"
              className="textInput"
              label="Rating"
              variant="outlined"
              value={candidateFeedback.rating}
              onChange={(e) =>
                setCandidateFeedback({
                  ...candidateFeedback,
                  rating: e.target.value,
                })
              }
              required
              fullWidth
              margin="normal"
              type="number"
              inputProps={{ step: "0.1", min: "0", max: "5" }}
            />
            {candidateFeedback.rating > 5 && (
              <div className="ratingError">Rating should be out of 5</div>
            )}
            <FormControl component="fieldset" className="radioGroup">
              <FormLabel component="legend" className="radioGroupLabel">
                Candidate Status
              </FormLabel>
              <RadioGroup
                aria-label="status"
                name="status"
                value={selectedOption ? "qualified" : "disqualified"}
                onChange={handleRadioChange}
                className="radioGroupOptions"
              >
                <FormControlLabel
                  value="qualified"
                  control={<Radio />}
                  label="Qualified"
                />
                <FormControlLabel
                  value="disqualified"
                  control={<Radio />}
                  label="Disqualified"
                />
              </RadioGroup>
            </FormControl>
            <Button className="btn" type="submit" variant="contained" fullWidth>
              Submit
            </Button>
          </form>
        </div>
      </div>
    </StyledDiv>
  ) : errorHandle.error ? (
    <CenterContainer>
      <div className="containers">
        <h1 className="stdName">{errorHandle?.error}</h1>
        <p>
          {errorHandle?.error === "Interview has not started yet"
            ? "Please attempt interview first"
            : ""}
        </p>
      </div>
    </CenterContainer>
  ) : null;
};

export default FeedbackForm;

const CenterContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #333;
  background-color: #f9f9f9;

  .containers {
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 20px auto;
    background-color: #fafafa;
    height: 50%;
    width: 50%;
    min-width: 500px;
    min-height: 400px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .stdName {
      display: block;
      font-size: clamp(1.5rem, 2vw + 1rem, 3rem);
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 729px;
  background-color: #f0f0f0;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 70%;
    height:729px
    max-width: 900px;
    position: relative;
  }

  .form-container {
    width: 100%;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 200;
    margin-bottom: 1rem;
    text-align: center;
  }

  .textInput {
    margin-bottom: 1rem;
  }

  .ratingError {
    color: red;
    position: absolute;
    bottom: 205px;
    font-size: 0.8rem;
    text-transform: capitalize;
    font-weight: 500;
    padding-left: 4px;
  }

  .radioGroup {
    margin: 1rem 0;
  }

  .radioGroupLabel {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .radioGroupOptions {
    display: flex;
    flex-flow:row;
    justify-content: space-around;
  }

  .btn {
    background-color: var(--cyan);
    color: var(--backgroundColor);
    font-size: 1.2rem;
    border-radius: 0.4rem;
    border: none;
    padding: 0.7rem 1rem;
    cursor: pointer;
    width: 35%;
    display: block;
    margin: auto;
    margin-top: 1rem;

    &:hover {
      background-color: var(--cyan);
    }
  }
`;

const StyledReactQuill = styled(ReactQuill)`
  .ql-toolbar {
    border-radius: 4px;
  }
  .ql-container {
    height: 150px;
    border-radius: 4px;
    margin: 10px 0;
  }
`;
