import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NewLoader from "../components/commonComponents/newLoader";

export const Limits = ({ accessToken, setLimits, i }) => {
  const [limitData, setLimitData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => {
    setLimits(false);
  };
  useEffect(() => {
    const getLimitData = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/limits`,
          config
        );
        setLimitData(response.data);
        setIsLoading(false);
      } catch (error) {
        throw error;
      }
    };
    getLimitData();
  }, []);

  return (
    <StyledLimitsPopup onClick={(e) => e.stopPropagation()}>
      <div className="popup">
        <div className="close-button" onClick={handleClose}>
          &times;
        </div>
        <h1 className="headings">Your Credits Balance</h1>
        {isLoading ? (
          <div className="loader">
            <NewLoader />
          </div>
        ) : (
          <table className="limits-table">
            <tbody>
              {Object.entries(limitData).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </StyledLimitsPopup>
  );
};

const StyledLimitsPopup = styled.div`
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 2000;
    width: 40%;
  }
  .headings {
    text-align: center;
    padding: 20px 0;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    background-color: #f1f1f1;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    text-align: center;

    &:hover {
      background-color: #ddd;
    }
  }
  .loader {
    display: flex;
    justify-content: center;
  }
  .limits-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;

    tr {
      display: flex;
      justify-content: space-between;

      &:nth-child(odd) {
        background-color: #f9f9f9;
      }

      &:nth-child(even) {
        background-color: #fff;
      }
    }

    td {
      padding: 8px 16px;

      &:first-child {
        width: 60%;
        font-weight: bold;
        color: #333;
      }

      &:last-child {
        width: 40%;
        color: #666;
      }
    }
  }
`;
