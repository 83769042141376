import React, { useState } from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import JobSeekerSidebar from "../components/Interviews/SeekerDashboard/jobSeekerSidebar";
import Metrics from "../components/Interviews/SeekerDashboard/Metrics";
import JobSeekerHeader from "../components/commonComponents/JobSeekerHeader";
import CreateResume from "../components/Interviews/SeekerDashboard/sidebarPages/CreateResume";
import EnhanceResume from "../components/Interviews/SeekerDashboard/sidebarPages/EnhanceResume";
import Subscription from "../components/Interviews/SeekerDashboard/sidebarPages/Subscription";
import ConfigureDash from "../components/Interviews/SeekerDashboard/sidebarPages/ConfigureDash";
import AllJobs from "../components/Interviews/SeekerDashboard/sidebarPages/AllJobs";
import AppliedJobs from "../components/Interviews/SeekerDashboard/sidebarPages/AppliedJobs";
import RecommendedJobs from "../components/Interviews/SeekerDashboard/sidebarPages/RecommendedJobs";
import SavedJobs from "../components/Interviews/SeekerDashboard/sidebarPages/SavedJobs";
import InterviewTabs from "../components/Interviews/InterviewTabs";
import { useDispatch } from "react-redux";
import { setCurrentItem, setCurrentMetric } from "../slices/configSlice";
import { useEffect } from "react";
import CvTable from "../components/Interviews/SeekerDashboard/seekerCommonComponents/CvTable";
import Inbox from "../components/Interviews/EmployerDashboard/sidebarPages/Inbox";
import { updateCandidateAvl, updateCompanyType, updateExp, updateFreshness, updateNoticePeriod, updateSalaryRange, updateSelectedLocations, updateSelectedSkills, updateWorkType } from '../slices/jobFilterSlice';
import CallSupport from "../components/commonComponents/CallSupport";
import ProfileWrapper from "../components/Interviews/SeekerDashboard/sidebarPages/ProfileWrapper";
import ParameterSelector from "../components/Interviews/CreateInterview/ParamaterSelector";
import { Limits } from "./Limits";


const JobSeekerDashboard = () => {
  const [isLimits, setLimits]=useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
  const currentItem = useSelector(state => state.config?.currentItem);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (event.currentTarget.performance.navigation.type === 1) {
        dispatch(setCurrentItem('dashboard'));
        dispatch(setCurrentMetric('interviewScheduled'));
        dispatch(updateExp([]));
        dispatch(updateSelectedLocations([]));
        dispatch(updateSelectedSkills([]));
        dispatch(updateWorkType([]));
        dispatch(updateSalaryRange([]));
        dispatch(updateFreshness([]));
        dispatch(updateNoticePeriod([]));
        dispatch(updateCompanyType([]));
        dispatch(updateCandidateAvl([]));
      }
    };

    window.addEventListener('load', handleBeforeUnload);

    return () => {
      window.removeEventListener('load', handleBeforeUnload);
    };
  }, [dispatch]);

  return (
    <MainBox>
      <JobSeekerHeader setLimits={setLimits} isLimits={isLimits}/>
      {          
        isLimits && <Limits accessToken={accessToken} setLimits={setLimits} />
      }
      <StyledContent>
        <JobSeekerSidebar />
        <MainContent>
          {currentItem === 'dashboard' &&
            <Metrics />
          }

          {currentItem === "configure-dashboard" && <ConfigureDash />}
          {currentItem === 'job-search' && <AllJobs />}
          {currentItem === 'applied-jobs' && <AppliedJobs />}
          {currentItem === 'recommended-jobs' && <RecommendedJobs />}
          {currentItem === 'saved-jobs' && <SavedJobs />}
          {currentItem === 'create-resume' && <CreateResume />}
          {currentItem === 'enhance-resume' && <EnhanceResume />}
          {currentItem === 'all-cvs' && <CvTable />}


          {currentItem === 'interview-dashboard' && <InterviewTabs />}
          {currentItem === 'practice-mock' && <ParameterSelector />}
          {currentItem === '' && <InterviewTabs />}
          {currentItem === 'profile' && <ProfileWrapper />}
          {currentItem === 'subscriptions' && <Subscription />}
          {currentItem === 'inbox' && <div style={{"marginLeft": "4rem"}}><Inbox /></div>}

          {currentItem === 'call-support' && <CallSupport />}
        </MainContent>
      </StyledContent>
    </MainBox>
  );
};


export default JobSeekerDashboard;


const MainBox = styled.div`
display: flex;
flex-direction: column;
min-height: 100vh;
width: 100%;
background-color: #f4f4f4;
`

const MainContent = styled.div`
  flex-grow: 1;
  margin-left: 16rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f4f4f4;
`;

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - 4rem);
  margin-top: 4rem;
  color: var(--color);
`;
