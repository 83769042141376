import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router";

import { useSelector } from "react-redux";
import EmployeMetrics from "../components/Interviews/EmployerDashboard/EmployerMetrics";
import EmployerSidebar from "../components/Interviews/EmployerDashboard/EmployerSidebar";
import EmployeProfile from "../components/Interviews/EmployerDashboard/sidebarPages/EmployerProfile";
import JdRegistration from "../components/Interviews/EmployerDashboard/sidebarPages/JdRegistration";
import CreateQuestion from "../components/Interviews/EmployerDashboard/sidebarPages/CreateQuestion";
import ScheduleInterview from "../components/Interviews/EmployerDashboard/Schedule/ScheduleInterview";
import ActiveJds from "../components/Interviews/EmployerDashboard/sidebarPages/ActiveJds";
import ManageTests from "../components/Interviews/EmployerDashboard/sidebarPages/ManageTests";
import ManageJds from "../components/Interviews/EmployerDashboard/Schedule/ManageJds";
import EmployerHeader from "../components/commonComponents/EmployerHeader";
import Subscription from "../components/Interviews/EmployerDashboard/sidebarPages/Subscription";
import Report from "../components/Interviews/EmployerDashboard/sidebarPages/Report";
import Inbox from "../components/Interviews/EmployerDashboard/sidebarPages/Inbox";
import Billing from "../components/Interviews/EmployerDashboard/sidebarPages/Billing";
import RegisterCandidate from "../components/Interviews/EmployerDashboard/sidebarPages/RegisterCandidate";
import RegisteredCandidates from "../components/Interviews/EmployerDashboard/sidebarPages/RegisteredCandidates";
import AvailableTest from "../components/Interviews/EmployerDashboard/sidebarPages/AvailableTest";
import ManageUsers from "../components/Interviews/EmployerDashboard/sidebarPages/ManageUsers";
import ConfigureReports from "../components/Interviews/EmployerDashboard/sidebarPages/ConfigureReports";
import InterviewDashboard from "../components/Interviews/EmployerDashboard/sidebarPages/InterviewDashboard";
import UploadCandidateProfile from "../components/Interviews/EmployerDashboard/sidebarPages/UploadCandidateProfile";
import EmpConfigureDash from "../components/Interviews/EmployerDashboard/sidebarPages/EmpConfigureDash";
import InterviewFlow from "../components/Interviews/EmployerDashboard/sidebarPages/InterviewFlow";
import RequestDemoPage from "../components/Interviews/EmployerDashboard/sidebarPages/RequestDemoPage";
import ManageAgencies from "../components/Interviews/EmployerDashboard/sidebarPages/ManageAgencies";
import JdListingTabs from "./agency/JdListingTabs";
import { useDispatch } from "react-redux";
import { setCurrentItem, setCurrentMetric } from "../slices/configSlice";
import CandidateListingTabs from "./agency/CandidateListingTabs";
import CallSupport from "../components/commonComponents/CallSupport";
import { Limits } from "./Limits";

const EmployerDashboard = () => {
  const [isLimits, setLimits]=useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const userType = useSelector((state) => state.auth.userType);
  const currentItem = useSelector((state) => state.config?.currentItem);
  const [selectedJDID, setSelectedJDID] = useState("");
  const [empClientCode, setEmpClientCode] = useState("");

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [empScheduledPage, setEmpScheduledPage] = useState({
    index: 1,
    jdId: null,
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (event.currentTarget.performance.navigation.type === 1) {
        dispatch(setCurrentItem("dashboard"));
        dispatch(
          setCurrentMetric(userType === "EMPLOYER" ? "interviews" : "jdListing")
        );
      }
    };

    window.addEventListener("load", handleBeforeUnload);

    return () => {
      window.removeEventListener("load", handleBeforeUnload);
    };
  }, [dispatch]);

  useEffect(() => {
    const func = () => {
      setLimits(false);
    };
    document.addEventListener("click", func);
    return () => {
      document.removeEventListener("click", func);
    };
  }, []);

  useEffect(() => {
    // if (!accessToken) navigate("/login");
    if (!accessToken) navigate("/login");
    if (clientCode == "otohire") navigate("/access-denied");

    let initialOpen =
      currentItem === "activeJds" ||
      currentItem === "create-tests" ||
      currentItem === "available-tests";
    if (initialOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [currentItem]);

  useEffect(() => {
    // if (!accessToken) navigate("/login");
    let initialOpen =
      currentItem === "candidate-register" ||
      currentItem === "candidate-registered";
    if (initialOpen) {
      setOpen2(true);
    } else {
      setOpen2(false);
    }
  }, [currentItem]);
  
  return (
    <MainBox>
      <EmployerHeader setLimits={setLimits} isLimits={isLimits}/>
      {
        isLimits && <Limits accessToken={accessToken} setLimits={setLimits} />
      }
      <StyledContent>
        <EmployerSidebar
          open={open}
          open2={open2}
          setOpen={setOpen}
          setOpen2={setOpen2}
        />
        <MainContent>
          {currentItem === "dashboard" && (
            <EmployeMetrics
              page={empScheduledPage}
              setPage={setEmpScheduledPage}
              setCurrentItem={setCurrentItem}
              setEmpClientCode={setEmpClientCode}
              setSelectedJDID={setSelectedJDID}
            />
          )}

          {currentItem === "configure-dashboard" && <EmpConfigureDash />}
          {currentItem === "jd-register" && <JdRegistration />}
          {currentItem === "manage-jds" && <JdRegistration />}
          {currentItem === "manage-ssubscriptions" && <JdRegistration />}

          {currentItem === "candidate-register" && <RegisterCandidate />}
          <div className="innerBox">
            {currentItem === "candidate-registered" && <RegisteredCandidates />}
          </div>
          {currentItem === "upload-profiles" && <UploadCandidateProfile />}
          {currentItem === "manage-tests" && <CreateQuestion />}
          <div className="innerBox">
            {currentItem === "activeJds" && <ActiveJds />}
          </div>
          {currentItem === "create-tests" && <ManageTests />}
          {currentItem === "available-tests" && <AvailableTest />}
          {currentItem === "interview-dashboard" && (
            <InterviewDashboard
              page={empScheduledPage}
              setPage={setEmpScheduledPage}
            />
          )}
          {currentItem === "interview-flow" && <InterviewFlow />}
          {currentItem === "schedule-Interview" && <ScheduleInterview/>}

          {currentItem === "profile" && <EmployeProfile />}
          {currentItem === "manage-users" && <ManageUsers />}
          {currentItem === "manage-agencies" && <ManageAgencies />}
          {currentItem === "subscriptions" && <Subscription />}
          {currentItem === "billing" && <Billing />}
          {currentItem === "inbox" && <Inbox />}
          {currentItem === "report" && <Report />}
          {currentItem === "configure-report" && <ConfigureReports />}

          {currentItem === "call-support" && <CallSupport />}
          {currentItem === "demo" && <RequestDemoPage />}

          {currentItem === "jdListing" && (
            <JdListingTabs
              setCurrentItem={setCurrentItem}
              setEmpClientCode={setEmpClientCode}
              setSelectedJDID={setSelectedJDID}
            />
          )}
          {currentItem == "candidateListing" && (
            <CandidateListingTabs
              setCurrentItem={setCurrentItem}
              empClientCode={empClientCode}
              selectedJDID={selectedJDID}
              setSelectedJDID={setSelectedJDID}
            />
          )}
        </MainContent>
      </StyledContent>
    </MainBox>
  );
};

export default EmployerDashboard;

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f4f4;
`;

const MainContent = styled.div`
  flex-grow: 1;
  margin-left: 17rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f4f4f4;

  .innerBox {
    width: 96%;
  }
`;

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - 4rem);
  margin-top: 4rem;
  background-color: var(--white);
  color: var(--color);
  background-color: #f4f4f4;
`;
