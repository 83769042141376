import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEmployer } from "../../../../functions/api/employers/profile/getEmployer";
import { toast } from "react-toastify";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styled from "styled-components";
import { timeZoneConversion } from "../../../../utils/timeZoneConversation";

function Row(props) {
  const { row, index } = props;
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const [companyDetails, setCompanyDetails] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getEmployer(accessToken, row?.clientCode);
        if (res) {
          setCompanyDetails(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getData();
  }, []);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${index % 2 == 1 ? "colored" : ""}`}
      >
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="logo tableCell"
        >
          <img src={companyDetails?.companyLogo} />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {companyDetails?.companyName}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.jdId}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.scheduledAt && timeZoneConversion(row?.scheduledAt)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.status}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const OngoingInterviews = ({ data, total }) => {
  if (total === 0) {
    return <h6 style={{ fontSize: "1.2rem" }}>No interview Here</h6>;
  }

  return (
    <Container1>
      <StyledBox>
        <TableContainer component={Paper} className="tableBox">
          <span className="title">Ongoing Interviews</span>
          <Table aria-label="collapsible table">
            <TableHead className="tableHead">
              <TableRow>
                <TableCell align="center" className="tableCell"></TableCell>
                <TableCell align="center" className="tableCell">
                  Company
                </TableCell>
                <TableCell align="center" className="tableCell">
                  JD ID
                </TableCell>
                <TableCell align="center" className="tableCell">
                  Scheduled Date/Time
                </TableCell>
                <TableCell align="center" className="tableCell">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {data?.map((row, index) => (
                <Row key={row?.jobId} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledBox>
    </Container1>
  );
};

export default OngoingInterviews;

const StyledBox = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  width: 100%;
  padding: 0;

  .colored {
    background-color: #ececec;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding-top: 1rem;

    .title {
      padding: 0 0 0 1.2rem;
      line-height: 3rem;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .btn {
    background-color: var(--cyan);
    padding: 0.5rem 0.8rem;
    border: none;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    font-family: var(--font);
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;

    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .tableBody {
    width: 100%;

    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .rowText {
    font-size: 0.75rem;
  }

  .logo {
    width: 2rem;
    height: 2rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10%;
    }
  }
`;

const Container1 = styled.div`
  width: 95%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
