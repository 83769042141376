import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled, { css } from "styled-components";
import actionDot from "../../../../assets/icons/threeDot.png";
import moveNextRoundIcon from "../../../../assets/icons/moveNextRoundIcon.png";
import putHoldIcon from "../../../../assets/icons/putOnHoldIcon.png";
import moveOutIcon from "../../../../assets/icons/moveOutInterviewIcon.png";
import { getAllTrackers } from "../../../../functions/api/interview/getAllTrackers";
import { useSelector } from "react-redux";
import {
  addResumes,
  addSelectedJd,
  resetInviteStatus,
  resetSelectedJd,
  resetSelection,
  toggleSelected,
} from "../../../../slices/invitationSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import TableSearchBar from "../commonComponents/TableSearchBar";
import EmpSelectInput from "../commonComponents/EmpSelectInput";
import EmpCommonButton from "../commonComponents/EmpCommonButton";
import {
  Pagination,
  PaginationSizeFilter,
} from "../../../commonComponents/Pagination";
import { updateTrackers } from "../../../../functions/api/employers/tracker/updateTrackers";
import { toast } from "react-toastify";
import Invite from "../Schedule/Invite";
import filterIcon from "../../../../assets/icons/filter.png";
import rescheduleIcon from "../../../../assets/icons/arrow.png";
import { RescheduledTimeAndDate } from "./RescheduledTimeAndDate";
import { updateStatus } from "../../../../functions/api/interview/updateStatus";
import threeDot from "../../../../assets/icons/threeDot.png";
import CommonDrawer from "../../../commonComponents/CommonDrawer";
import { ViewDetails } from "../Schedule/ViewDetails";
import { ModalBox } from "../../../commonComponents/ModalBox";

function Row(props) {
  const {
    row,
    rowsLength,
    index,
    handleSelectArray,
    filterParams,
    handleRescheduling,
    handleTrackerUpdate,
    handleMoveNextRounds,
    accessToken,
    getData,
    action,
    setActions,
    handleModalOpened,
    resetActions,
  } = props;

  const dropdownRef = useRef(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [viewRequest, setViewRequest] = React.useState({
    right: false,
  });
  const dispatch = useDispatch();
  const selected = useSelector((state) => state?.invite?.selectedRows);
  const openDropdown = (index) => {
    setOpenDropdownIndex(index);
  };

  const closeAllDropdowns = () => {
    setOpenDropdownIndex(-1);
  };

  const handleSelectChange = (row) => {
    const isSelected = selected.find((item) => item?.id === row?.id);
    const isChecked = isSelected ? isSelected?.selected : false;

    dispatch(toggleSelected({ id: row?.id, value: !isChecked }));

    if (isChecked) {
      handleSelectArray(row?.id, false);
    } else {
      handleSelectArray(row, true);
    }
  };

  const handleHoldNdMoveOut = (text) => {
    handleTrackerUpdate(text, {
      jdId: "",
      resumeIds: [row?.resumeId],
      status: text,
      trackerIds: [row.id],
      reason: text === "HOLD" ? action?.holdReason : action?.moveOutReason,
    });
  };

  const canceledInterview = (text) => {
    console.log(text);
    //call cancelation function
    const makeApiCall = async () => {
      const payload = {
        data: "CANCEL",
        extra: action?.cancellationReason,
      };
      try {
        const response = await updateStatus(
          row.interviewId,
          payload.data,
          accessToken,
          payload.extra
        );
        if (response.response?.data?.status == "FAILED") {
          throw new Error(JSON.stringify(response?.response?.data));
        }
        toast.success("Interview has been canceled");
      } catch (error) {
        toast.error(error?.response?.data?.notify?.message || error?.message);
      }
    };
    makeApiCall();
    getData();
    resetActions();
  };

  //function to open and close Drawer
  const toggleViewDetail = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    console.log("running");
    setViewRequest({ ...viewRequest, [anchor]: open });
  };
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeAllDropdowns();
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  return (
    <React.Fragment>
      {action?.isOpened && (
        <ModalBox
          values={
            action?.type === "CANCEL"
              ? action?.cancellationReason
              : action?.type === "NOT_SELECTED"
              ? action?.moveOutReason
              : action?.holdReason
          }
          onConfirm={() => {
            if (action?.individual) {
              action?.type === "CANCEL"
                ? canceledInterview(action?.type)
                : handleHoldNdMoveOut(action?.type);
            } else {
              handleTrackerUpdate(action?.type);
            }
          }}
          setActions={setActions}
          action={action}
          resetActions={resetActions}
        />
      )}
      <CommonDrawer
        toggleDrawer={toggleViewDetail}
        state={viewRequest}
        component={<ViewDetails row={row} />}
      />
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${index % 2 == 1 ? "colored" : ""}`}
      >
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          <BoxRow isLast={index >= rowsLength - 2}>
            <input
              type="checkbox"
              className="three-dots"
              checked={selected?.find((item) => item?.id === row?.id)?.selected}
              onChange={() => handleSelectChange(row)}
            />
          </BoxRow>
        </TableCell>
        <TableCell align="center" className="tableCell col2">
          {row?.name}
        </TableCell>
        <TableCell align="center" className="tableCell col3">
          {row?.email}
        </TableCell>
        <TableCell align="center" className="tableCell col4">
          <a
            href="#"
            onClick={toggleViewDetail("right", true)}
            style={{ textDecorationLine: "none" }}
          >
            {row?.jdId?.toUpperCase()}
          </a>
        </TableCell>
        <TableCell align="center" className="tableCell col5">
          {row?.recruiter}
        </TableCell>
        <TableCell align="center" className="tableCell col6">
          {row?.hiringManager}
        </TableCell>
        <TableCell align="center" className="tableCell col7">
          {row?.round}
        </TableCell>
        <TableCell align="center" className="tableCell col9">
          {row?.status}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell col10"
        >
          <BoxRow
            isLast={index >= rowsLength - 2}
            style={{ justifyContent: "center" }}
          >
            <img
              src={threeDot}
              style={{
                width: "0.8rem",
                height: "0.8rem",
                cursor: "pointer",
                border: "0.08rem solid grey",
                padding: "0.3rem",
                borderRadius: "0.3rem",
              }}
              className={`threeDotIcon ${
                openDropdownIndex === index ? "active" : ""
              }`}
              onClick={() => {
                if (openDropdownIndex === index) {
                  closeAllDropdowns();
                } else if (openDropdownIndex !== index) {
                  closeAllDropdowns();
                  openDropdown(index);
                }
              }}
            />

            <div
              className={`dropdown-content ${
                openDropdownIndex === index ? "open" : ""
              }`}
              ref={dropdownRef}
            >
              {(filterParams === "COMPLETED" ||
                filterParams === "CANCELED") && (
                <span
                  className="dropdownText"
                  onClick={() => handleMoveNextRounds(row)}
                >
                  <img src={moveNextRoundIcon} /> Move to next Round
                </span>
              )}
              {filterParams === "COMPLETED" && (
                <span
                  className="dropdownText"
                  onClick={() =>
                    handleTrackerUpdate("SELECTED", {
                      jdId: "",
                      resumeIds: [row?.resumeId],
                      status: "SELECTED",
                      trackerIds: [row.id],
                    })
                  }
                >
                  <img src={moveNextRoundIcon} /> Mark as selected
                </span>
              )}
              <span
                className="dropdownText"
                onClick={() => handleModalOpened("HOLD", "individual")}
              >
                <img src={putHoldIcon} /> Put on Hold
              </span>
              <span
                className="dropdownText"
                onClick={() => handleModalOpened("NOT_SELECTED", "individual")}
              >
                <img src={moveOutIcon} /> Move out from Interview
              </span>
              {filterParams !== "COMPLETED" && (
                <span
                  className="dropdownText"
                  onClick={() => handleRescheduling(row)}
                >
                  <img src={rescheduleIcon} />
                  Reschedule
                </span>
              )}
              {filterParams === "SCHEDULED" && (
                <span
                  className="dropdownText"
                  onClick={() => handleModalOpened("CANCEL", "individual")}
                >
                  <img src={moveOutIcon} /> Cancel Interview
                </span>
              )}
            </div>
          </BoxRow>
        </TableCell>
      </TableRow>{" "}
    </React.Fragment>
  );
}

const InterviewFlow = ({ setPage }) => {
  const [tableRows, setTableRows] = useState([]);
  const [filterParams, setFilterParams] = useState("COMPLETED");
  const [inviteProcess, setInviteProcess] = useState({});
  const [rescheduleProcess, setRescheduleProcess] = useState({});
  const [reason, setReason] = useState("");
  const [checked, setChecked] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [jdSet, setJdSet] = useState([]);
  const [filteredJdId, setFilteredJdId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [allInterviews, setAllInterviews] = useState([]);
  const [page1, setPage1] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(0);
  const [action, setActions] = useState({
    cancellationReason: "",
    holdReason: "",
    moveOutReason: "",
    isOpened: false,
    type: "",
    individual: false,
  });

  const filterRef = useRef(null);

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage1(1);
  };

  const handleRescheduling = (row) => {
    setRescheduleProcess({ isOpened: true, row });
  };
  // running an effect to empty selectedArray whenever filter value changes
  useEffect(() => {
    setSelectedArray([]);
    dispatch(resetSelection());
    dispatch(resetSelectedJd());
    dispatch(resetInviteStatus());
    setChecked(false);
  }, [filterParams]);

  useEffect(() => {
    selectedArray.forEach((row) => {
      dispatch(toggleSelected({ id: row?.id, value: false }));

      handleSelectArray(row?.id, false);
    });
  }, [tableRows, filteredData]);

  const handlePageChange = (change) => {
    if (change && page1 < Math.ceil(+total / +size)) {
      setPage1((prev) => prev + 1);
    } else if (!change && page1 > 1) {
      setPage1((prev) => prev - 1);
    }
  };

  const handleSelectArray = (id, action) => {
    if (action) {
      setSelectedArray((prev) => [...prev, id]);
    } else {
      setSelectedArray((prev) => prev.filter((item) => item?.id !== id));
    }
  };
  const handleSelectChanges = () => {
    setChecked(!checked);
    setSelectedArray([]);
    let arr = search ? filteredData : tableRows;
    arr.forEach((row) => {
      dispatch(toggleSelected({ id: row?.id, value: !checked }));

      if (checked) {
        handleSelectArray(row?.id, false);
      } else {
        handleSelectArray(row, true);
      }
    });
  };

  useEffect(() => {
    let arr = search ? filteredData : tableRows;
    setChecked(
      selectedArray.length >= 1 && selectedArray.length === arr.length
        ? true
        : false
    );
  }, [selectedArray, tableRows, filteredData]);

  // func to update tracker status as Shortlist or Rejected
  const handleTrackerUpdate = async (val, payloads) => {
    try {
      const payloadData = payloads || {
        jdId: "",
        resumeIds: selectedArray?.map((user) => user?.resumeId),
        status: val,
        trackerIds: selectedArray?.map((user) => user?.id),
        reason:
          action.type === "HOLD" ? action?.holdReason : action?.moveOutReason,
      };

      const updatedRes = await updateTrackers(
        payloadData,
        accessToken,
        clientCode
      );

      if (updatedRes) {
        setUpdateTrigger(!updateTrigger);
        toast.success(
          `${
            val === "HOLD"
              ? "Hold"
              : val === "SELECTED"
              ? "Selected"
              : "Moved out of Interview"
          } Successfully`
        );
        await getData();
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    } finally {
      resetActions();
    }
  };

  const handleMoveNextRound = () => {
    dispatch(addResumes([...selectedArray]));
    setInviteProcess({
      isOpen: true,
      jdId: selectedArray[0]?.jdId,
    });
    // navigate(`/schedule/invite/${selectedArray[0]?.jdId}`);
  };
  const handleMoveNextRounds = (row) => {
    dispatch(addResumes([row]));
    setInviteProcess({
      isOpen: true,
      jdId: row?.jdId,
    });
  };

  const filterArr = [
    { value: "COMPLETED", text: "Completed" },
    { value: "SCHEDULED", text: "Scheduled" },
    { value: "EXPIRED", text: "Expired" },
    { value: "CANCELED", text: "Canceled" },
  ];

  const getData = async () => {
    try {
      const res = await getAllTrackers(
        accessToken,
        clientCode,
        search ? 1 : page1,
        search ? 1000000 : size,
        filteredJdId === "all" ? "" : filteredJdId,
        filterParams,
        "IN_PROCESS"
      );
      let array = res?.data?.data;
      if (array) {
        const finalResult = array?.reduce((acc, it) => {
          let current = it?.interview;
          let jdInfoReq = {
            name: current?.userName || it?.matchedRecord?.name,
            jdId: it?.jdId,
            recruiter: current?.recruiter,
            hiringManager: current?.hiringManager,
            round: it?.stage,
            interviewName: current?.interviewName,
            status: current?.status,
            resumeId: current?.resumeId,
            id: it?.id,
            interviewId: current?.id,
            email: it?.userEmail,
            resumeId: it?.matchedRecord?.resumeId,
            interviewerId:
              current?.interviewerEmail || current?.initiatedByEmail,
            meetingLink: current?.meetingLink,
            testType: current?.testType,
            jobSummary: current?.jobSummary,
          };

          return [...acc, jdInfoReq];
        }, []);
        setTableRows(finalResult);
        setAllInterviews(finalResult);
        setTotal(res?.data?.total);
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const handleModalOpened = (text, text2) => {
    setActions((prev) => ({
      ...prev,
      isOpened: true,
      type: text,
      individual: text2 === "individual" ? true : false,
    }));
  };

  const resetActions = () => {
    setActions({
      cancellationReason: "",
      holdReason: "",
      moveOutReason: "",
      isOpened: false,
      type: "",
      individual: false,
    });
  };

  useEffect(() => {
    const getAllData = async () => {
      try {
        const res = await getAllTrackers(
          accessToken,
          clientCode,
          1,
          1000000,
          "",
          "",
          "IN_PROCESS"
        );
        let allTrackers = res?.data?.data;

        const uniqueSet = new Set(allTrackers.map((item) => item.jdId));
        setJdSet([...uniqueSet]);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    getAllData();
  }, []);

  useEffect(() => {
    getData();
  }, [page1, size, filterParams, search, filteredJdId]);

  useEffect(() => {
    if (searchValue?.trim()) {
      setSearch(true);
      setFilteredData(() =>
        allInterviews?.filter(
          (item) =>
            item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            item?.jdId?.toLowerCase()?.includes(searchValue?.toLowerCase())
        )
      );
    } else {
      setSearch(false);
    }
  }, [searchValue]);

  return (
    <>
      {inviteProcess?.isOpen ? (
        <Content>
          <Invite
            jdId={inviteProcess.jdId}
            setInviteProcess={setInviteProcess}
          />
        </Content>
      ) : rescheduleProcess?.isOpened ? (
        <Content>
          <RescheduledTimeAndDate
            row={rescheduleProcess.row}
            setRescheduleProcess={setRescheduleProcess}
            reason={reason}
          />
        </Content>
      ) : (
        <Content>
          <TableContainer component={Paper} className="tableBox">
            <div className="titleBox">
              <span className="title">Manage Interview Flow</span>
            </div>

            <SearchBarContainer>
              <TableSearchBar value={searchValue} setValue={setSearchValue} />
              <EmpSelectInput
                value={filterParams}
                setValue={setFilterParams}
                optionsArr={filterArr}
              />
            </SearchBarContainer>
            <Table aria-label="collapsible table">
              <TableHead className="tableHead">
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className="tableCell col1"
                  >
                    <BoxRow>
                      <input
                        type="checkbox"
                        className="three-dots"
                        checked={checked}
                        onChange={() => handleSelectChanges()}
                      />
                    </BoxRow>
                  </TableCell>
                  <TableCell align="center" className="tableCell col2">
                    Name
                  </TableCell>
                  <TableCell align="center" className="tableCell col3">
                    Email
                  </TableCell>
                  <TableCell align="center" className="tableCell flexCell col4">
                    JD ID
                    <img
                      src={filterIcon}
                      className={`icon ${filterOpen == true ? "active" : ""}`}
                      onClick={() => setFilterOpen(!filterOpen)}
                    />
                    <div
                      className={`filterBox ${filterOpen ? "open" : ""}`}
                      ref={filterRef}
                    >
                      <div className="checkboxInput">
                        <input
                          type="radio"
                          value="all"
                          checked={filteredJdId === "all"}
                          onChange={() => setFilteredJdId("all")}
                          id="all"
                        />
                        <label htmlFor="all">ALL</label>
                      </div>
                      {/* TODO api hit for all details to use getting option in jd filtration */}
                      {jdSet?.map((jd, i) => (
                        <div className="checkboxInput">
                          <input
                            type="radio"
                            value={jd}
                            checked={filteredJdId === jd}
                            onChange={() => setFilteredJdId(jd)}
                            id={jd}
                          />
                          <label htmlFor={jd}>{jd?.toUpperCase()}</label>
                        </div>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell align="center" className="tableCell col5">
                    Recruiter
                  </TableCell>
                  <TableCell align="center" className="tableCell col6">
                    Hiring Manager
                  </TableCell>
                  <TableCell align="center" className="tableCell col7">
                    Current Round
                  </TableCell>
                  <TableCell align="center" className="tableCell col9">
                    Status
                  </TableCell>

                  <TableCell align="center" className="tableCell col10">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {(search ? filteredData : tableRows)?.map((row, index) => (
                  <Row
                    key={row?.id}
                    rowsLength={tableRows?.length}
                    row={row}
                    index={index}
                    handleSelectArray={handleSelectArray}
                    handleRescheduling={handleRescheduling}
                    handleTrackerUpdate={handleTrackerUpdate}
                    handleMoveNextRounds={handleMoveNextRounds}
                    filterParams={filterParams}
                    updateTrigger={updateTrigger}
                    setReason={setReason}
                    reason={reason}
                    accessToken={accessToken}
                    getData={getData}
                    action={action}
                    setActions={setActions}
                    resetActions={resetActions}
                    handleModalOpened={handleModalOpened}
                  />
                ))}
              </TableBody>
            </Table>
            {!search && (
              <div className="paginationBox">
                <PaginationSizeFilter
                  size={size}
                  handleSizeChange={handleSizeChange}
                />
                <Pagination
                  total={total}
                  size={size}
                  page={page1}
                  handlePageChange={handlePageChange}
                  setPage={setPage1}
                />
              </div>
            )}

            <div className="btnBox">
              {selectedArray?.length > 0 && (
                <EmpCommonButton
                  text="Put on Hold"
                  func={() => handleModalOpened("HOLD", "Bulk")}
                />
              )}
              {selectedArray?.length > 0 && (
                <EmpCommonButton
                  text="Move out from Interview"
                  func={() => handleModalOpened("NOT_SELECTED", "Bulk")}
                />
              )}
              {selectedArray?.length > 0 &&
                (filterParams === "COMPLETED" ||
                  filterParams === "CANCELED") && (
                  <>
                    <EmpCommonButton
                      text="Move to next Round"
                      func={() => handleMoveNextRound()}
                    />
                    {filterParams === "COMPLETED" && (
                      <EmpCommonButton
                        text="Mark as Selected"
                        func={() => handleTrackerUpdate("SELECTED")}
                      />
                    )}
                  </>
                )}
            </div>
          </TableContainer>
        </Content>
      )}
    </>
  );
};

export default InterviewFlow;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  gap: 1rem;
`;

const Content = styled.div`
  margin: 1rem 0% 2rem 0%;
  width: 94%;
  padding: 0 1.5%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding-top: 1rem;
    margin-bottom: 1.5rem;

    .title {
      padding-left: 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
    }

    .titleBox {
      width: 99%;
      padding: 0.5rem 0rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &::-webkit-scrollbar {
      width: 0rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 0rem;
    }

    & {
      scrollbar-width: none;
    }
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;

    .icon {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }

    .flexCell {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      position: relative;
    }

    .filterBox {
      position: absolute;
      left: 75%;
      top: -1rem;
      display: none;
      background-color: var(--white);
      border: 0.075rem solid grey;
      border-radius: 0.3rem;
      height: 7rem;
      overflow-y: auto;
      padding: 1rem 0;
      z-index: 100;

      .checkboxInput {
        display: flex;
        padding: 0.2rem 1rem;
        font-size: 0.8rem;
        font-weight: 500;
        align-items: start;
        gap: 0.3rem;
      }

      input {
        height: 1rem;
        cursor: pointer;
      }

      label {
        cursor: pointer;
      }

      &::-webkit-scrollbar {
        width: 0.4rem;
      }

      &::-webkit-scrollbar-track {
        background: lightgrey;
        border-radius: 0.4rem;
      }

      &::-webkit-scrollbar-thumb {
        background: grey;
        width: 0.4rem;
        border-radius: 0.4rem;
      }

      & {
        scrollbar-width: none;
      }
    }

    .filterBox.open {
      display: block;
    }

    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
      white-space: nowrap; /* Prevents text wrapping */
      // border:1px solid red;
    }

    .col1 {
      width: 100px;
    }

    .col2 {
      width: 150px;
    }

    .col3 {
      width: 200px;
    }

    .col4 {
      width: 160px;
      display: flex;
      justify-content: center;
    }

    .col5 {
      width: 100px;
    }

    .col6 {
      width: 120px;
    }

    .col7 {
      width: 100px;
    }

    .col8 {
      width: 120px;
    }

    .col9 {
      width: 100px;
    }

    .col10 {
      width: 80px;
    }
  }

  .tableBody {
    width: 100%;

    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
      padding: 16px 3px;
    }
  }

  .btnBox {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    gap: 2rem;
  }
`;

const BoxRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  .three-dots {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--white);
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 10%;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    min-width: 13rem;
    justify-content: start;
    padding: 0.5rem 0.5rem;

    ${(props) =>
      props.isLast &&
      css`
        bottom: 1.4rem;
        right: 10%;
      `}s
  }

  .dropdown-content span {
    padding: 0.3rem 0.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color);
    cursor: pointer;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content.open {
    display: block;
  }

  .threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }

  .dropdownText {
    img {
      width: 0.6rem;
      height: 0.6rem;
      cursor: pointer;
      border: 0.08rem solid grey;
      padding: 0.15rem;
      border-radius: 0.2rem;
    }
  }
  .dropdownText:hover {
    background: #e2dbdb36;
  }
`;
