import React, { useEffect } from "react";
import App from "./App";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { fetchGoogleClientId } from "./slices/getGoogleClientIdSlice";

const Main = () => {
  const dispatch = useDispatch();
  const {clientId} = useSelector((state) => state.clientId);
  useEffect(() => {
    dispatch(fetchGoogleClientId());
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId?.data?.value}>
      <ToastContainer />
      <App />
    </GoogleOAuthProvider>
  );
};

export default Main;
