import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import logo from "../../assets/otohireNewLogo.jpg";
import { useSelector } from "react-redux";
import profileIcon from "../../assets/profileIcon.png";
import notificationIcon from "../../assets/icons/notification.png";
import demoIcon from "../../assets/icons/demoIconNew.jpg";
import profileFeedback from "../../assets/icons/profileFeedback.png";
import profileHelp from "../../assets/icons/profileHelp.png";
import profileReset from "../../assets/icons/profileReset.png";
import profileLogout from "../../assets/icons/profileLogout.png";

import { persistor } from "../../store";
import { logout } from "../../slices/authSlice";
import { useDispatch } from "react-redux";
import { setCurrentItem } from "../../slices/configSlice";
import { toast } from "react-toastify";
import { getEmployer } from "../../functions/api/employers/profile/getEmployer";
import { getUnreadCount } from "../../functions/api/employers/notifications/getUnreadCount";

const EmployerHeader = ({ setLimits, isLimits }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth?.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const user = useSelector((state) => state.auth?.userData?.user);
  const userType = useSelector((state) => state.auth?.userType);
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const handleLogout = () => {
    persistor.purge();
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await getEmployer(accessToken, clientCode);
        if (res) {
          console.log(res);
          setCompanyName(res?.data?.companyName);
          setCompanyLogo(res?.data?.companyLogo);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    const getNotifyCount = async () => {
      try {
        const res = await getUnreadCount(accessToken, clientCode);
        if (res) {
          setUnreadNotificationCount(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getUser();
    getNotifyCount();
  }, []);

  const handleLimitPopup = (e) => {
    setLimits(!isLimits);
    console.log("limit showing");
    e.stopPropagation();
  };
  return (
    <StyledDiv>
      <div id="left">
        <img src={logo} onClick={() => navigate("/")} />
      </div>

      <div id="right">
        {userType === "EMPLOYER" && (
          <span
            onClick={() => dispatch(setCurrentItem("interview-dashboard"))}
            className="coloredText"
          >
            Go to Interview Dashboard
          </span>
        )}

        <div className="notificationIcon" onClick={() => navigate("/demo")}>
          <span className="demoIcon">
            <img src={demoIcon} />
            <span className="altText">Schedule Demo</span>
          </span>
        </div>

        <div
          className="notificationIcon"
          onClick={() => dispatch(setCurrentItem("inbox"))}
        >
          <img src={notificationIcon} className="notifyIcon" />{" "}
          <span className="notifyCount">{unreadNotificationCount}</span>
        </div>

        <div className="profileIcon">
          {companyLogo ? (
            <img src={companyLogo} className="profileImgNew" />
          ) : (
            <img src={profileIcon} className="profileImg" />
          )}
        </div>

        <div class="dropdown" id="dropdown">
          <span
            className="titleText span"
            style={{ marginBottom: "0rem", border: "none" }}
            onClick={() => dispatch(setCurrentItem("profile"))}
          >
            Signed In as <b>{companyName || user?.firstName}</b>
          </span>
          {/* <span onClick={() => navigate("/feedback")} className="span">
            <img src={profileFeedback} /> Feedback
          </span> */}
          <span onClick={handleLimitPopup} className="span">
            <img src={profileFeedback} /> Your Credits
          </span>
          <span onClick={() => navigate("/support")} className="span">
            <img src={profileHelp} /> Help
          </span>
          <span onClick={() => navigate("/resetPwd/employer")} className="span">
            <img src={profileReset} /> Reset Password
          </span>
          <span onClick={handleLogout} className="span">
            <img src={profileLogout} /> Logout
          </span>
        </div>
      </div>
    </StyledDiv>
  );
};

export default EmployerHeader;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color);
  width: 90%;
  padding: 0% 5%;
  height: 4rem;
  position: fixed;
  z-index: 2000;
  background-color: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  #left > img {
    height: 3.5rem;
    margin-left: -5%;
    cursor: pointer;
  }

  h1 {
    margin: 0;
    color: #add8e6;
  }

  #right {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .coloredText {
    font-size: 0.85rem;
    color: var(--color);
    font-weight: 600;
    cursor: pointer;
  }

  .coloredText:hover {
    font-weight: 700;
    text-decoration: underline;
  }

  .demo {
    background: linear-gradient(to bottom, #2282a4, var(--cyan));
    padding: 0.4rem 0.5rem;
    color: var(--white);
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
    border-radius: 0.5rem;
  }

  .demoIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 1.7rem;
      height: 1.7rem;
    }

    .altText {
      color: var(--color);
      font-size: 0.6rem;
      position: absolute;
      top: 3rem;
      display: none;
    }
  }

  .demoIcon:hover .altText {
    display: block;
  }

  .demo:hover + {
    background: linear-gradient(to bottom, #8accdc, var(--cyan));
  }

  .link {
    text-decoration: none;
  }

  .Icon {
    color: var(--white);
  }

  .notificationIcon {
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    margin-top: 0.1rem;
    display: flex;
    position: relative;

    .notifyIcon {
      width: 100%;
      height: 100%;
    }

    .notifyCount {
      position: absolute;
      font-size: 0.85rem;
      font-weight: 600;
      top: -0.45rem;
      right: -0.45rem;
      margin-top: 0;
      display: flex;
      background-color: red;
      color: var(--white);
      border-radius: 50%;
      min-width: 1.25rem;
      min-height: 1.25rem;
      align-items: center;
      justify-content: center;
    }
  }

  .profileIcon {
    position: relative;
    cursor: pointer;
  }

  .profileImg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .profileImgNew {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }

  .dropdown {
    display: none;
    position: absolute;
    top: 75%;
    right: 0.5vw;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 1rem;
    font-weight: 400;
    font-size: 0.8rem;
    border-radius: 0.5rem;

    .titleText {
      font-weight: 400;
      font-size: 0.9rem;
    }

    img {
      width: 1rem;
    }
  }

  .dropdown .span {
    display: flex;
    padding: 8px 10px;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s ease;
    border-bottom: 0.1rem groove #f6f6f6;
    gap: 0.5rem;
    align-items: center;
  }

  .dropdown .span:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
  }

  .profileIcon:hover + #dropdown {
    display: block;
  }

  .dropdown:hover {
    display: block;
  }
`;
