import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { jobListings } from "../../utils/contantData";
import { getInterviewByStatus } from "../../functions/api/getInterviewByStatus";
import Loader from "../commonComponents/Loader";
import view from "../../assets/icons/visible.png";
import CommonDrawer from "../commonComponents/CommonDrawer";
import SeekerInterviewDetails from "./SeekerDashboard/sidebarPages/SeekerInterviewDetails";
import {
  dateConversion,
  timeZoneConversion,
} from "../../utils/timeZoneConversation";
import SeekerTableSearchBar from "./SeekerDashboard/seekerCommonComponents/SeekerTableSearchBar";
import {
  Pagination,
  PaginationSizeFilter,
} from "../commonComponents/Pagination";
import { toast } from "react-toastify";
import { getEmployer } from "../../functions/api/employers/profile/getEmployer";
import ConfigurableModal from "./SeekerDashboard/seekerCommonComponents/ConfigurableModal";
import { shouldStartInterview } from "../../functions/api/interview/shouldStartInterview";
import { getInvites } from "../../functions/api/employers/schedule/getInvites";

function Row(props) {
  const { row, index, setIsLoading, setLoaderMessage } = props;
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getEmployer(accessToken, row?.clientCode);
        if (res) {
          setCompanyDetails(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    getData();
  }, []);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${index % 2 == 1 ? "colored" : ""}`}
      >
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="logo tableCell"
        >
          <img src={companyDetails?.companyLogo} />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {companyDetails?.companyName}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.jdId}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.slotDate && dateConversion(row?.slotDate)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.stage}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          <Button onClick={() => navigate(`/slot-selection/${row?.token}`)}>
            Select Slot
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const InterviewInvites = () => {
  const [appliedJobs, setAppliedJobs] = useState();
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(0);

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    try {
      const getInvitesData = async () => {
        const res = await getInvites(page, size, accessToken);
        if (res) {
          setFilteredJobs(res?.data?.data);
          setTotal(res?.data?.total);
        }
      };
      getInvitesData();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }, [size, page]);

  return (
    <Container1>
      <StyledBox>
        {isLoading && <Loader message={loaderMessage} />}
        {!isLoading && (
          <TableContainer component={Paper} className="tableBox">
            <span className="title">Interview Invites</span>
            <Table aria-label="collapsible table">
              <TableHead className="tableHead">
                <TableRow>
                  <TableCell align="center" className="tableCell"></TableCell>
                  <TableCell align="center" className="tableCell">
                    Company
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    JD ID
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    Slot Date
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    Round
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {filteredJobs?.map((row, index) => (
                  <Row
                    key={row?.jobId}
                    row={row}
                    index={index}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    loaderMessage={loaderMessage}
                    setLoaderMessage={setLoaderMessage}
                  />
                ))}
              </TableBody>
            </Table>
            <div className="paginationBox">
              <PaginationSizeFilter
                size={size}
                handleSizeChange={handleSizeChange}
              />
              <Pagination
                total={total}
                size={size}
                page={page}
                handlePageChange={handlePageChange}
                setPage={setPage}
              />
            </div>
          </TableContainer>
        )}
      </StyledBox>
    </Container1>
  );
};

export default InterviewInvites;

const StyledBox = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  width: 100%;
  padding: 0;

  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding-top: 1rem;

    .title {
      padding: 0 0 1rem 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
      display: flex;
    }
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .btn {
    background-color: var(--cyan);
    padding: 0.5rem 0.8rem;
    border: none;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    font-family: var(--font);
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;

    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .tableBody {
    width: 100%;

    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .rowText {
    font-size: 0.75rem;
  }

  .logo {
    width: 2rem;
    height: 2rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10%;
    }
  }
`;

const Container1 = styled.div`
  width: 95%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const Button = styled.button`
  background-color: var(--cyan);
  color: ${(props) => (props.disabled ? "#e9e9e9" : "var(--white)")};
  padding: 0.5rem 0.8rem;
  border: none;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  text-decoration: none;
  font-family: var(--font);
`;
