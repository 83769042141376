import axios from "axios";

export const getEmployer = async (
  accessToken,
  clientCode,
  ) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
  };
  try {
    const response =
      await axios.get(`${process.env.REACT_APP_API_URL}/api/employers/${clientCode}`,config);

    console.log("Data:", response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
